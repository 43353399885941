var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"editEpi"},[(_vm.epiPrepare)?_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('DetallesEpi'))+" ")])]),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Producto'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Producto'),"label-for":"epi-product"}},[_c('SelectProducts',{attrs:{"type":"epi","readonly":true},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2909354056)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Estado'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Estado'),"label-for":"epi-status"}},[_c('v-select',{attrs:{"label":"alias","filterable":false,"searchable":false,"options":_vm.selectStatus,"placeholder":_vm.$t('Estado'),"readonly":true,"disabled":true},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$t('status.epis.' + option.alias))+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$t('status.epis.' + option.alias))+" ")]}}],null,true),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2464378044)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('NumeroSerie'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('NumeroSerie'),"label-for":"epi-serial_number"}},[_c('b-form-input',{attrs:{"name":"serial_number","placeholder":_vm.$t('NumeroSerie')},model:{value:(_vm.epi.serial_number),callback:function ($$v) {_vm.$set(_vm.epi, "serial_number", $$v)},expression:"epi.serial_number"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3307668549)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('NumeroAlbaran'),"label-for":"epi-pedido_num_id"}},[_c('b-form-input',{attrs:{"name":"pedido_num_id","placeholder":_vm.$t('NumeroAlbaran')},model:{value:(_vm.epi.pedido_num_id),callback:function ($$v) {_vm.$set(_vm.epi, "pedido_num_id", $$v)},expression:"epi.pedido_num_id"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('FechaCompra'),"label-for":"epi-purchase_date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"name":"purchase_date","readonly":"true","disabled":true},model:{value:(_vm.purchase_date),callback:function ($$v) {_vm.purchase_date=$$v},expression:"purchase_date"}})],1)],1),(_vm.currentCompany.id === 3)?_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Coste'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Coste'),"label-for":"equipos-coste"}},[_c('b-input-group',{attrs:{"append":"€"}},[_c('b-form-input',{attrs:{"name":"equipos-coste","type":"number","placeholder":_vm.$t('Coste')},model:{value:(_vm.epi.coste),callback:function ($$v) {_vm.$set(_vm.epi, "coste", $$v)},expression:"epi.coste"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,637605156)})],1):_vm._e(),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Localizacion'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Localizacion origen'),"label-for":"epi-location"}},[_c('v-select',{attrs:{"label":"name","filterable":false,"searchable":false,"options":_vm.selectLocations,"placeholder":_vm.$t('Localizacion'),"disabled":_vm.currentRole && (_vm.currentRole !== 'admin_cliente' && _vm.currentRole !== 'super_admin' && _vm.currentRole !== 'admin_empresa'),"readonly":"readonly"},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}}],null,true),model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1453640889)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('ProximaRevision'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('ProximaRevision'),"label-for":"epi-next_review"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"name":"next_review","readonly":true,"disabled":true},model:{value:(_vm.next_review),callback:function ($$v) {_vm.next_review=$$v},expression:"next_review"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3831302019)})],1),_c('b-col',{attrs:{"sm":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Descripcion'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Descripcion'),"label-for":"epi-description"}},[_c('quill-editor',{model:{value:(_vm.epi.description),callback:function ($$v) {_vm.$set(_vm.epi, "description", $$v)},expression:"epi.description"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2745013314)})],1),_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('Enviar'))+" ")])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }